<template>
  <v-container fluid>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="encashments"
        :loading="loading"
        :options.sync="options"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :server-items-length="getCompanyEncashmentsTotalCount"
        :footer-props="{ 'items-per-page-options': [5, 10, 15, 20], 'show-current-page': true, 'show-first-last-page': true }"
        class="elevation-1"
        ref="dataTable"
      >
        <template v-slot:top>
          <v-toolbar color="indigo lighten-5" flat>
            <BackButton />
            <v-toolbar-title>Звіти по інкасаціям</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-btn :to="{ name: 'EncashmentCardCreate', hash: '#create' }" color="primary" small>Додати інкасацію</v-btn>
          </v-toolbar>
          <v-container fluid>
            <v-row class="mt-2 mb-4">
              <v-col cols="12" sm="6" md="3" lg="2">
                <v-datetime-picker
                  v-model="fromDate"
                  @input="paginateTo"
                  :textFieldProps="textFieldProps"
                  :datePickerProps="datePickerProps"
                  :timePickerProps="timePickerProps"
                  timeFormat="HH:mm:ss"
                  dateFormat="dd-MM-yyyy"
                  clear-text="очистити"
                  ok-text="так"
                  label="Дата та час від"
                >
                  <template v-slot:dateIcon>
                    <v-icon>mdi-calendar</v-icon>
                  </template>
                  <template v-slot:timeIcon>
                    <v-icon>mdi-clock-outline</v-icon>
                  </template>
                </v-datetime-picker>
              </v-col>
              <v-col cols="12" sm="6" md="3" lg="2" offset-md="1">
                <v-datetime-picker
                  v-model="toDate"
                  @input="paginateTo"
                  :textFieldProps="textFieldProps"
                  :datePickerProps="datePickerProps"
                  :timePickerProps="timePickerProps"
                  dateFormat="dd-MM-yyyy"
                  timeFormat="HH:mm:ss"
                  clear-text="очистити"
                  ok-text="так"
                  label="Дата та час до"
                >
                  <template v-slot:dateIcon>
                    <v-icon>mdi-calendar</v-icon>
                  </template>
                  <template v-slot:timeIcon>
                    <v-icon>mdi-clock-outline</v-icon>
                  </template>
                </v-datetime-picker>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-slot:header>
          <thead>
            <tr>
              <th colspan="2" style="border-right: thin solid rgba(0, 0, 0, 0.12)"></th>
              <th
                colspan="9"
                class="text-center"
                style="border-right: thin solid rgba(0, 0, 0, 0.12); border-top: thin solid rgba(0, 0, 0, 0.12)"
              >
                Подія
              </th>
              <th colspan="4" class="text-center" style="border-top: thin solid rgba(0, 0, 0, 0.12)">Місце</th>
            </tr>
          </thead>
        </template>
        <template v-slot:header.created_at="{ header }">
          <TableTitleDateFilter
            :title-text="header.text"
            @select="(selected) => (selectedDates = selected)"
            @ok="applyDateFilter('created_at', selectedDates)"
          />
        </template>
        <template v-slot:header.manual_recalculation="{ header }">
          <TableTitleFilter
            :options="ENCASHMENT_TYPES"
            :title-text="header.text"
            item-value="value"
            item-text="title"
            :multiple="false"
            @select="(selected) => (manualRecalculation = selected)"
            @ok="paginateTo"
          />
        </template>
        <template v-slot:header.collector="{ header }">
          <TableTitleFilter
            :options="users"
            :title-text="header.text"
            item-value="id"
            @select="(selected) => (selectedCollectors = selected)"
            @ok="paginateTo"
          >
            <template v-slot:item="{ item }">{{ item.first_name }} {{ item.last_name }} ({{ item.phone }})</template>
            <template v-slot:selection="{ item }">{{ item.first_name }} {{ item.last_name }}</template>
          </TableTitleFilter>
        </template>
        <template v-slot:header.balance_holder="{ header }">
          <TableTitleFilter
            :options="getBalanceHolderFilter"
            :title-text="header.text"
            @select="setSelectedBalanceHolders"
            @ok="paginateTo"
          />
        </template>
        <template v-slot:header.service_point="{ header }">
          <TableTitleFilter
            :options="getServicePointFilter"
            :title-text="header.text"
            @select="setSelectedServicePoints"
            @ok="paginateTo"
          />
        </template>
        <template v-slot:header.terminal="{ header }">
          <TableTitleFilter
            :options="getTerminalFilter"
            :title-text="header.text"
            item-text="value"
            @select="setSelectedTerminals"
            @ok="paginateTo"
          >
            <template v-slot:item="{ item }">T{{ item.value }}</template>
          </TableTitleFilter>
        </template>
        <template v-slot:item.created_at="{ item }">
          <div>{{ item.created_at | getShortTime }}</div>
          <span class="caption">{{ item.created_at | getShortDate }}</span>
        </template>
        <template v-slot:item.updated_at="{ item }">
          <div>{{ item.updated_at | getShortTime }}</div>
          <span class="caption">{{ item.updated_at | getShortDate }}</span>
        </template>
        <template v-slot:item.manual_recalculation="{ item }">
          <span v-if="item.manual_recalculation">Ручна</span>
          <span v-else>Авто</span>
        </template>
        <template v-slot:item.collector="{ item }">
          <span v-if="item.collector">{{ item.collector.first_name }} {{ item.collector.last_name }}</span>
        </template>
        <template v-slot:item.balance_holder="{ item }">
          <router-link
            v-if="item.balance_holder.id"
            :to="{ name: 'PartnerCard', params: { id: currentCompanyId, partnerId: item.balance_holder.id } }"
            class="text-decoration-none"
          >
            {{ item.balance_holder.name }}
          </router-link>
        </template>
        <template v-slot:item.service_point="{ item }">
          <router-link
            v-if="item.service_point.id"
            :to="{ name: 'ServicePointCard', params: { id: currentCompanyId, servicePointId: item.service_point.id } }"
            class="text-decoration-none"
          >
            {{ item.service_point.name }}
          </router-link>
        </template>
        <template v-slot:item.terminal="{ item }">
          <router-link
            v-if="item.terminal.id"
            :to="{ name: 'TerminalCard', params: { id: currentCompanyId, terminalId: item.terminal.id } }"
            class="text-decoration-none"
          >
            T{{ item.terminal.id }}
          </router-link>
        </template>
        <template v-slot:item.device_id="{ item }">
          <router-link
            :to="{ name: 'DeviceCard', params: { id: currentCompanyId, deviceId: item.device_id } }"
            class="text-decoration-none"
          >
            {{ item.device_id }}
          </router-link>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom :open-on-focus="false">
            <template v-slot:activator="{ on, attrs }">
              <v-icon small class="mr-2" @click="editItem(item)" v-bind="attrs" v-on="on"> mdi-pencil-outline </v-icon>
            </template>
            <span>Редагувати</span>
          </v-tooltip>
          <v-tooltip bottom :open-on-focus="false">
            <template v-slot:activator="{ on, attrs }">
              <v-icon small @click="navigateToEncashmentCard(item)" v-bind="attrs" v-on="on"> mdi-eye-outline </v-icon>
            </template>
            <span>Переглянути</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="resetData"> Оновити </v-btn>
        </template>
        <template v-slot:body.append>
          <tr class="custom-row-class">
            <td class="text-start">Загалом</td>
            <td class="text-start v-data-table_divider"></td>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <td class="text-start" v-bind="attrs" v-on="on">
                  {{ encashmentsTotalCounts.encashments_count && encashmentsTotalCounts.encashments_count.toLocaleString() }}
                </td>
              </template>
              Загальна кількість інкасацій
            </v-tooltip>
            <td class="text-start"></td>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <td class="text-start" v-bind="attrs" v-on="on">
                  {{ encashmentsTotalCounts.total_cash_sum && encashmentsTotalCounts.total_cash_sum.toLocaleString() }}
                </td>
              </template>
              Загальна сума
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <td class="text-start" v-bind="attrs" v-on="on">
                  {{ encashmentsTotalCounts.total_actual_sum && encashmentsTotalCounts.total_actual_sum.toLocaleString() }}
                </td>
              </template>
              Загальна сума перерахунку
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <td class="text-start" v-bind="attrs" v-on="on">
                  {{ encashmentsTotalCounts.total_payouts && encashmentsTotalCounts.total_payouts.toLocaleString() }}
                </td>
              </template>
              Загальна сума відрахувань
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <td class="text-start" v-bind="attrs" v-on="on">
                  {{ encashmentsTotalCounts.total_difference && encashmentsTotalCounts.total_difference.toLocaleString() }}
                </td>
              </template>
              Загальна різниця
            </v-tooltip>
            <td class="text-start"></td>
            <td class="text-start"></td>
            <td class="text-start"></td>
            <td class="text-start"></td>
            <td class="text-start"></td>
            <td class="text-start"></td>
            <td class="text-start"></td>
          </tr>
        </template>
      </v-data-table>
    </v-card>

    <LogsPackageModal
      @closePackageDialog="dialogPacket = false"
      :dialogPacket="dialogPacket"
      :dialogPacketType="dialogPacketType"
      :selectedPacket="selectedPacket"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { ROUTER_LINKS } from '@/const/router-links.enum'
import ConvertDate from '@/mixins/convertDate'
import resetTableData from '@/mixins/resetTableData'
import ConvertDevicesTypes from '@/mixins/convertDevicesTypes'
import ManageFiltersOptions from '@/mixins/manageFiltersOptions'
import SetLogsFiltersOptions from '@/mixins/setLogsFiltersOptions'
import OpenLogsPackageDialog from '@/mixins/openLogsPackageDialog'
import CustomAutocompleteFilter from '@/mixins/customAutocompleteFilter'
import moment from 'moment'
import BackButton from '@/components/common/BackButton.vue'
import TableTitleFilter from '@/components/common/filters/TableTitleFilter/TableTitleFilter.vue'
import sortUtils from '@/mixins/sortUtils'
import filterUtils from '@/mixins/filterUtils'
import TableTitleDateFilter from '@/components/common/filters/TableTitleFilter/TableTitleDateFilter.vue'

const DEFAULT_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'
const ENCASHMENT_TYPES = [
  {
    title: 'Авто',
    value: 0,
  },
  {
    title: 'Ручна',
    value: 1,
  },
]

export default {
  name: 'EncashmentsList',

  components: {
    TableTitleDateFilter,
    TableTitleFilter,
    BackButton,
    LogsPackageModal: () => import('@/components/dialogs/LogsPackageModal'),
  },

  mixins: [
    CustomAutocompleteFilter,
    ConvertDevicesTypes,
    ConvertDate,
    ManageFiltersOptions,
    SetLogsFiltersOptions,
    OpenLogsPackageDialog,
    resetTableData,
    sortUtils,
    filterUtils,
  ],

  props: {
    instanceId: {
      type: Number,
      required: false,
      default: null,
    },
    hideCompany: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: () => ({
    ROUTER_LINKS: ROUTER_LINKS,
    MENU_MIN_WIDTH: 450,
    MENU_MAX_WIDTH: 450,
    ENCASHMENT_TYPES: ENCASHMENT_TYPES,
    eventTypesMenu: false,
    eventsMenu: false,
    usersMenu: false,
    companiesMenu: false,
    collectorsMenu: false,
    encashmentTypesMenu: false,
    balanceHoldersMenu: false,
    servicePointsMenu: false,
    terminalsMenu: false,
    devicesTypesMenu: false,
    devicesMenu: false,
    isEventTypesFilterApplied: false,
    isEventsFilterApplied: false,
    isUsersFilterApplied: false,
    isEncashmentTypesFilterApplied: false,
    isCollectorsFilterApplied: false,
    isBalanceHoldersFilterApplied: false,
    isServicePointsFilterApplied: false,
    isTerminalsFilterApplied: false,
    isDevicesTypesFilterApplied: false,
    isDevicesFilterApplied: false,
    loading: false,
    filterLoading: false,
    sortBy: 'id',
    sortDesc: true,
    options: {},
    payload: null,
    searchPerformer: null,
    additionalInfoEventTypesList: [],
    page: 1,
    textFieldProps: {
      hideDetails: 'auto',
      dense: true,
    },
    datePickerProps: {
      'first-day-of-week': 1,
    },
    timePickerProps: {
      format: '24hr',
      useSeconds: true,
    },
    fromDate: null,
    toDate: null,
    manualRecalculation: null,
    // selectedCompany: null,
    selectedEventTypeGroups: [],
    selectedEventTypes: [],
    selectedEvents: [],
    selectedCollectors: [],
    selectedPacket: null,
    searchEventType: null,
    searchEvent: null,
    isUserLoading: false,
    isEventTypesLoading: false,
    isEventsLoading: false,
    servicePointsFilterKey: 0,
    terminalsFilterKey: 0,
    devicesFilterKey: 0,
    headers: [
      { text: 'Дата і час інкасації', value: 'created_at', sortable: true, width: 110 },
      { text: 'Дата і час створення картки інкасації', value: 'updated_at', sortable: true, divider: true, width: 110 },
      { text: 'id', value: 'id', sortable: true, width: 100 },
      { text: 'Тип', value: 'manual_recalculation', sortable: false, width: 75 },
      { text: 'Сума інкасації, грн', value: 'cash_sum', sortable: true, width: 100 },
      { text: 'Ручний перерахунок, грн', value: 'actual_sum', sortable: true, width: 100 },
      { text: 'Відрахування, грн', value: 'payout', sortable: true, width: 100 },
      { text: 'Різниця, грн', value: 'difference', sortable: true, width: 100 },
      { text: 'Днів після попередньої інкасації', value: 'days_after_prev_encashment', sortable: false },
      { text: 'Коментар', value: 'comment', sortable: false },
      { text: 'Інкасатор', value: 'collector', sortable: false, divider: true },
      { text: 'Орендодавець', value: 'balance_holder', sortable: false },
      { text: 'Точка', value: 'service_point', sortable: false },
      { text: 'Термінал', value: 'terminal', sortable: false },
      { text: '', value: 'actions', sortable: false, width: '125px', align: 'center' },
    ],
  }),

  computed: {
    ...mapState('encashments', ['encashments', 'encashmentsTotalCounts']),
    ...mapState('users', ['users']),

    ...mapGetters('encashments', ['getCompanyEncashmentsTotalCount']),
    ...mapGetters('companies', [
      'getUserFilter',
      'getBalanceHolderFilter',
      'getServicePointFilter',
      'getTerminalFilter',
      'getDeviceFilter',
    ]),

    currentCompanyId() {
      return this.$route.params.id
    },
    convertedFromDate() {
      if (!this.fromDate) return ''
      return moment(this.fromDate).format(DEFAULT_DATE_TIME_FORMAT)
    },
    convertedToDate() {
      if (!this.toDate) return ''
      return moment(this.toDate).format(DEFAULT_DATE_TIME_FORMAT)
    },
  },

  watch: {
    options: {
      handler() {
        this.paginateTo()
      },
      deep: true,
    },
    searchPerformer: {
      immediate: true,
      async handler(user) {
        const userPayload = {
          companyId: this.currentCompanyId,
          performer: 1,
          user,
        }
        this.isUserLoading = true
        await this.loadUsers(userPayload)
        this.isUserLoading = false
      },
    },
  },

  created() {
    this.initialize()
  },

  beforeDestroy() {},

  methods: {
    ...mapActions('users', ['loadUsers']),
    ...mapActions('companies', ['loadFilter']),
    ...mapActions('encashments', ['loadEncashments']),
    ...mapActions('logs', ['displayWarningAlert']),

    async initialize() {
      this.payload = {
        companyId: this.currentCompanyId,
      }
      await this.loadFilter(this.currentCompanyId)
    },

    async paginateTo() {
      if (this.currentCompanyId !== 'undefined') {
        this.loading = true
        this.payload = {
          ...this.payload,
          page: this.options.page,
          limit: this.options.itemsPerPage,
          sort: this.sortObject,
          collector: this.selectedCollectors.map((item) => item.id).join(),
          balanceHolder: this.selectedBalanceHolders.map((item) => item.value).join(),
          servicePoint: this.selectedServicePoints.map((item) => item.value).join(),
          terminal: this.selectedTerminals.map((item) => item.value).join(),
          createdAt:
            this.convertedFromDate && this.convertedToDate
              ? {
                  fromDate: this.convertedFromDate,
                  toDate: this.convertedToDate,
                }
              : null,
          manualRecalculation: this.manualRecalculation?.length
            ? this.manualRecalculation.map((item) => item.value).join()
            : null,
          ...this.filterObject,
        }
        await this.loadEncashments(this.payload)
        this.loading = false
      } else {
        await this.displayWarningAlert({ message: 'Оберіть компанію' })
        await this.$router.replace('/spa')
      }
    },

    editItem(item) {
      this.$router.push({ name: 'EncashmentCard', params: { encashmentId: item.id }, hash: '#edit' })
    },

    navigateToEncashmentCard(item) {
      this.$router.push({ name: 'EncashmentCard', params: { encashmentId: item.id } })
    },
  },
}
</script>

<style scoped lang="scss">
.custom-row-class {
  background-color: #e0e0e0;
}
</style>
